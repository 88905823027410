




// @ts-nocheck
import vue from 'vue';
import ShortCodeApi from '@/apis/shortcode.api';

export default vue.extend({
    name: 'ShortCode',
    data() {
        return {
            redirectUrl: window.location.origin,
        };
    },
    created() {
        const {shortCode} = this.$route.params;
        ShortCodeApi
            .resolve(shortCode)
            .then(({
                url, community, script, conversation, user, lang,
            }) => {
                if (url) {
                    this.redirectUrl = url;
                }

                const shortCodeData = this.$cookies.get('data');
                if (shortCodeData && shortCodeData[shortCode]) {
                    const shortCodeDataConversation = shortCodeData[shortCode].conversation;
                    const shortCodeDataCommunity = shortCodeData[shortCode].community;
                    const shortCodeDataScript = shortCodeData[shortCode].script;
                    if (shortCodeDataConversation !== conversation
                        || shortCodeDataCommunity !== community
                        || shortCodeDataScript !== script
                    ) {
                        this.$router.push({name: 'Closed'});
                        return;
                    }
                }

                this.$cookies.set('data', {
                    [shortCode]: {
                        community, script, conversation,
                    },
                }, 2147483647);

                if (!url) {
                    this.addCommunityPart(community);

                    if (conversation && user) {
                        this.addScriptPart(script, conversation);
                    }
                }

                // // Check if 'l' query exists
                if (this.hasLanguageButNoDutch(lang || this.$route.query.l)) {
                    this.redirectUrl = this.addQueryParameterToUrl(this.redirectUrl, 'l', this.$route.query.l || lang);
                }

                this.pushRedirect();
            }).catch((e) => {
                if (e.response.status === 404) {
                    this.$router.push({name: 'Closed'});
                }
            });
    },
    methods: {
        addCommunityPart(community) {
            this.appendToRedirectUrl(`/${community}/chat`);
        },
        addScriptPart(script, conversation: string|null) {
            this.appendToRedirectUrl(`/${script}`);
            if (conversation) {
                this.redirectUrl = this.addQueryParameterToUrl(this.redirectUrl, 'cid', conversation);
            } else {
                this.appendToRedirectUrl('/anonymous');
            }
        },
        addQueryParameterToUrl(originalUrl: string, key: string, value: string) {
            const urlObj = new URL(originalUrl, window.location.origin); // Parse URL to avoid manual query string manipulation
            urlObj.searchParams.set(key, value);
            return urlObj.toString();
        },
        pushRedirect(): void {
            window.location.href = this.redirectUrl;
        },
        appendToRedirectUrl(text: string) {
            this.redirectUrl += text;
        },
        hasLanguageButNoDutch(lang) {
            return (lang && lang !== 'nl');
        },
    },
});
