import {eventCallback} from '@/helpers/event-dispatcher';
import InstructionModel from '@/models/instruction.model';

export default (): EventListener => eventCallback(
    ({action, delay}: InstructionModel) => {
        if (!action) {
            return;
        }

        const {params} = action;

        if (!params) {
            return;
        }

        if (params.action === 'redirect') {
            window.setTimeout(
                () => window.location.assign(params.url),
                delay,
            );
        }
    },
);
