<template>
    <div id="conversation-loader"></div>
</template>

<script>
export default {
    name: 'ConversationLoader',
    created() {
        const {community, script, conversation} = this.$route.params;
        this.$router.push(`/${community}/chat/${script}?cid=${conversation}`);
    },
};
</script>
