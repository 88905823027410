/* eslint @typescript-eslint/camelcase: 0 */
import {eventCallback} from '@/helpers/event-dispatcher';
import InstructionModel from '@/models/instruction.model';

export default (): EventListener => eventCallback(
    ({action, delay}: InstructionModel) => {
        if (!action) {
            return;
        }

        const {auto_redirect, url} = action;

        if (auto_redirect && url) {
            window.setTimeout(
                () => window.location.assign(url),
                delay,
            );
        }
    },
);
