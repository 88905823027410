export type EventListenerConfig = {
    type: string;
    callback: EventListenerOrEventListenerObject | null;
    options?: AddEventListenerOptions;
};

export function eventCallback(callback: Function): EventListener {
    return (event: Event): void => {
        if (event instanceof CustomEvent) {
            callback(event.detail, event);
        } else {
            callback(event);
        }
    };
}

export default class EventDispatcher implements EventTarget {
    private eventListeners = [] as Array<EventListenerConfig>;

    addEventListener(
        type: string,
        callback: EventListenerOrEventListenerObject | null,
        options?: AddEventListenerOptions | boolean,
    ): void {
        if (typeof options === 'boolean') {
            throw new Error('Event dispatcher does not support `useCapture` option.');
        }

        this.eventListeners.push({type, callback, options});
    }

    dispatchEvent(event: Event): boolean {
        const listeners = this.eventListeners.filter(
            (listener: EventListenerConfig) => listener.type === event.type,
        );

        listeners.forEach((listener: EventListenerConfig) => {
            const {options = {signal: null, once: false}, callback} = listener;
            const {signal, once} = {
                signal: null as AbortSignal|null,
                ...options,
            };

            if (callback === null) {
                return;
            }

            if (signal?.aborted) {
                this.removeEventListener(
                    listener.type,
                    listener.callback,
                    listener.options,
                );
                return;
            }

            if ('handleEvent' in callback) {
                callback.handleEvent(event);
            } else {
                callback(event);
            }

            if (once) {
                this.removeEventListener(
                    listener.type,
                    listener.callback,
                    listener.options,
                );
            }
        });

        return event.cancelable && event.defaultPrevented;
    }

    removeEventListener(
        type: string,
        callback: EventListenerOrEventListenerObject | null,
        options?: EventListenerOptions | boolean,
    ): void {
        const listeners: EventListenerConfig[] = [];

        this.eventListeners.forEach((listener: EventListenerConfig) => {
            if (listener.type !== type
                || listener.callback !== callback
                || listener.options !== options
            ) {
                listeners.push(listener);
            }
        });

        this.eventListeners = listeners;
    }
}
