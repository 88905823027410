






import vue, {PropType} from 'vue';
import InstructionModel from '@/models/instruction.model';

function prepareContext(options: Record<string, string|string[]>): Record<string, string> {
    return Object.keys(options).reduce(
        (carry: Record<string, string>, key: string) => ({
            ...carry,
            [key]: Array.isArray(options[key])
                ? Array.of(options[key]).join(', ')
                : String(options[key]),
        }),
        {},
    );
}

export default vue.extend({
    props: {
        validationError: {
            type: String,
            required: true,
        },
        instruction: {
            type: Object as PropType<InstructionModel>,
            required: true,
        },
    },
    computed: {
        context(): object {
            const {instruction} = this;
            const {action, question} = instruction;
            let context = {};

            if (action && !['crmAuth', 'crmApi'].includes(action.type)) {
                context = action.params as {};
            }

            if (question) {
                context = question.options as {};
            }

            return prepareContext(context);
        },
    },
});
