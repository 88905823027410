















// eslint-disable-next-line
// @ts-ignore
import Compress from 'compress.js/src/index';
import QuestionInput from '@/components/answer-input/QuestionInput.vue';
import UploadButton from '@/components/answer-input/control/UploadButton.vue';

export default QuestionInput.extend({
    name: 'File',
    components: {UploadButton},
    methods: {
        onFileSuccess(file: File): void {
            this.$emit('valid', true);
            this.$emit('onFileSuccess', file);

            this.$nextTick(() => {
                this.$emit('submitForm');
            });
        },
        triggerError(error: string): void {
            this.onError(new Error(error));
        },
        onError(error: Error): void {
            this.$emit('valid', false);
            this.$emit('error', error.message);
        },
    },
});
