




































import vue from 'vue';
import {applyLuminanceFilter} from '@/helpers/color-utilities';

export default vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            default: 1,
        },
        value: {
            type: String,
            required: true,
        },
        minLabel: {
            type: String,
            default: '',
        },
        maxLabel: {
            type: String,
            default: '',
        },
    },
    watch: {
        progressColor(color: string): void {
            this.applyIconColorFilter(color);
        },
        value(): void {
            this.validateInput();
        },
    },
    mounted(): void {
        this.applyIconColorFilter();
        this.validateInput();
    },
    computed: {
        progressColor(): string {
            return this.$store.state.styling?.web['slider_background-color-left'] ?? '#B8C400FF';
        },
        progress(): number {
            const {min, max, value} = this;

            return (Math.min(parseFloat(value), max) - min) / (max - min);
        },
        range(): Array<string> {
            const {
                min: start,
                max,
                step,
                fractionDigits,
            } = this;
            const stop = max + 1;
            return Array(Math.ceil((stop - start) / step))
                .fill(start)
                .map((x, y) => (x + y * step).toFixed(fractionDigits));
        },
        fractionDigits(): number {
            const [, fractionDigits] = `${this.step}.`.split('.');
            return Number(fractionDigits);
        },
    },
    methods: {
        applyIconColorFilter(color: string|null = null): void {
            applyLuminanceFilter(
                this.$refs.iconContainer as HTMLElement,
                color ?? this.progressColor,
            );
        },
        validateInput(): void {
            const value = Number(this.value);
            let valid = true;

            const {
                min,
                max,
                range,
                fractionDigits,
            } = this;

            if (valid && value < min) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_LOW');
                valid = false;
            }

            if (valid && max < value) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_HIGH');
                valid = false;
            }

            if (valid && !range.includes(value.toFixed(fractionDigits))) {
                this.$emit('error', 'ERROR.VALIDATION.STEP_SIZE');
                valid = false;
            }

            this.$emit('validated', valid);
        },
    },
});
