








import vue from 'vue';

export default vue.extend({
    data() {
        return {
            community: this.$route.params.community,
            script: this.$route.params.script,
        };
    },
    computed: {
        frameSrc(): string {
            return `https://chat.abbi.dev/${this.community}/chat/${this.script}/anonymous`;
        },
    },
});
