




















import vue, {PropType} from 'vue';
import InstructionModel from '@/models/instruction.model';

export default vue.extend({
    props: {
        instruction: {
            type: Object as PropType<InstructionModel>,
            required: true,
        },
    },
    computed: {
        params(): Record<string, string> {
            return this.instruction.action?.params ?? {};
        },
        action(): string {
            return this.params.action ?? '';
        },
        url(): string {
            return this.params.url ?? '';
        },
        message(): string {
            return this.params.message ?? '';
        },
    },
});
