/* eslint @typescript-eslint/no-explicit-any: 0 */
import AbbiApi from '@/apis/abbi.api';

export default class CommunityApi extends AbbiApi {
    public static getStyle(communitySlug: string): Promise<any> {
        return CommunityApi.get(`${CommunityApi.url}/${communitySlug}/style/chat`);
    }

    public static getSidemenu(communitySlug: string): Promise<any> {
        return CommunityApi.get(`${CommunityApi.url}/${communitySlug}/sidemenu`);
    }
}
