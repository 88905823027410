












import vue from 'vue';

export default vue.extend({
    props: {
        icon: {
            type: String,
            required: true,
        },
        enabled: {
            type: Boolean,
            required: true,
        },
        shape: {
            type: String,
            required: true,
        },
        hide: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        style(): object {
            return {
                opacity: (this.hide && !this.enabled ? '0' : '1'),
            };
        },
    },
});
