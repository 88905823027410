




















import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Date',
    data() {
        return {
            valid: this.skipable,
            value: '',
        };
    },
    computed: {
        net() {
            return this.question.options?.minimum || '';
        },
        nlt() {
            return this.question.options?.maximum || '';
        },
    },
    methods: {
        validateInput($event: InputEvent) {
            const inputElement = $event.target as HTMLInputElement;
            const input = this.value;

            const net = new Date(this.net);
            const nlt = new Date(this.nlt);

            this.valid = true;
            if (this.net && net > new Date(input)) {
                this.valid = false;
                this.$emit('error', 'ERROR.VALIDATION.DATE_NET');
            } else if (this.nlt && nlt < new Date(input)) {
                this.valid = false;
                this.$emit('error', 'ERROR.VALIDATION.DATE_NLT');
            } else if (!/^\d{4,}-\d{1,2}-\d{1,2}$/.test(input)) {
                this.valid = false;
                this.$emit('error', this.$t('ERROR.VALIDATION.DATE_FORMAT'));
            } else if (!inputElement.checkValidity()) {
                this.$emit('error', inputElement.validationMessage);
                this.valid = false;
            }
            this.$emit('valid', this.valid);
        },
    },
});
