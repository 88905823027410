export interface RgbColor {
    red: number;
    green: number;
    blue: number;
}

export interface RgbaColor extends RgbColor {
    alpha: number;
}

export type RgbChannel = keyof RgbColor;

export function parseHexByte(component: string): number {
    return Number.parseInt(component, 16) / 255;
}

const hexColorExpression = /^#?(?<red>[0-9a-f]{2})(?<green>[0-9a-f]{2})(?<blue>[0-9a-f]{2})(?<alpha>[0-9a-f]{2})?/i;

export function parseHexColor(value: string): RgbaColor {
    const color: Record<string, string> | undefined = (
        hexColorExpression.exec(value)?.groups ?? undefined
    );

    if (color === undefined) {
        throw new Error('Invalid color format');
    }

    return {
        red: parseHexByte(color.red),
        green: parseHexByte(color.green),
        blue: parseHexByte(color.blue),
        alpha: parseHexByte(color.alpha ?? '00'),
    };
}

// @see https://www.w3.org/WAI/GL/wiki/Relative_luminance
const luminanceChannels: RgbColor = {
    red: 0.2126,
    green: 0.7152,
    blue: 0.0722,
};

export const rgbChannels = Object.keys(luminanceChannels) as Array<RgbChannel>;

export function getLuminance(color: RgbColor): number {
    return rgbChannels.reduce(
        (luminance: number, channel: RgbChannel): number => (
            luminance + (luminanceChannels[channel] * color[channel])
        ),
        0,
    );
}

export function withinLuminanceRange(
    color: RgbaColor,
    luminanceLowerBound: number,
    alphaUpperBound: number,
): boolean {
    return color.alpha < alphaUpperBound || getLuminance(color) > luminanceLowerBound;
}

export function isColorLuminant(
    color: string,
    luminanceLowerBound = 0.8,
    alphaUpperBound = 0.25,
): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            const colorDto = parseHexColor(color);
            const isLuminant = !withinLuminanceRange(colorDto, luminanceLowerBound, alphaUpperBound);
            resolve(isLuminant);
        } catch (e) {
            reject(e);
        }
    });
}

export function isBrightColor(
    color: string,
    luminanceLowerBound = 0.8,
    alphaUpperBound = 0.25,
): boolean {
    try {
        const colorDto = parseHexColor(color);
        return withinLuminanceRange(colorDto, luminanceLowerBound, alphaUpperBound);
    } catch (e) {
        return false;
    }
}

export function applyLuminance(
    element: HTMLElement,
    color: string,
    property: string,
    onBright: string,
    onDark: string,
): void {
    isColorLuminant(color).then((isLuminant) => {
        element.style.setProperty(
            property,
            isLuminant ? onBright : onDark,
            'important',
        );
    });
}

export function applyLuminanceFilter(
    element: HTMLElement,
    color: string,
): void {
    applyLuminance(element, color, '--filter', 'none', 'invert()');
}
