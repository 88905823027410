











import vue from 'vue';

interface Dot {
    key: string;
    style: object;
}

export default vue.extend({
    props: {
        numDots: {
            type: Number,
            default: 3,
        },
        animationDuration: {
            type: Number,
            default: 2.5,
        },
    },
    computed: {
        componentStyle(): object {
            const {animationDuration} = this;

            return {
                '--animation-duration': `${animationDuration}s`,
            };
        },
        dotDelay(): number {
            const {numDots, animationDuration} = this;
            return animationDuration / numDots / 2;
        },
        dots(): Array<Dot> {
            const {numDots, dotDelay} = this;
            const dots = [];

            for (let dotIndex = 1; dotIndex <= numDots; dotIndex++) {
                dots.push({
                    key: dotIndex.toString(),
                    style: {
                        'animation-delay': `${dotIndex * dotDelay}s`,
                    },
                });
            }

            return dots;
        },
    },
});
