import getUserAgentInfo from '@/helpers/user-agent';

function canAnimateHeight(): boolean {
    const {ios} = getUserAgentInfo();
    return !ios || ios.major > 16;
}

export interface Features {
    canAnimateHeight: boolean;
}

const features = {};

Object.defineProperty(
    features,
    'canAnimateHeight',
    {
        get: canAnimateHeight,
    },
);

export default features as Features;
