import Choice from '@/components/answer-input/Choice.vue';
import Date from '@/components/answer-input/Date.vue';
import Email from '@/components/answer-input/Email.vue';
import Nps from '@/components/answer-input/Nps.vue';
import Numeric from '@/components/answer-input/Numeric.vue';
import Currency from '@/components/answer-input/Currency.vue';
import Emoji from '@/components/answer-input/Emoji.vue';
import File from '@/components/answer-input/File.vue';
import Open from '@/components/answer-input/Open.vue';
import InstructionModel, {ActionOrQuestion, InstructionType} from '@/models/instruction.model';
import AnswerInput from '@/components/answer-input/index.vue';
import QuestionModel, {QuestionType} from '@/models/question.model';
import ActionModel from '@/models/action.model';
import QuestionInput from '@/components/answer-input/QuestionInput.vue';
import CrmInput from '@/components/answer-input/CrmInput.vue';
import ActionInput from '@/components/answer-input/ActionInput.vue';
import StarRatingInput from '@/components/answer-input/numeric/StarRatingInput.vue';

export const questionInputs: Record<QuestionType, typeof QuestionInput> = {
    choice: Choice,
    multiple: Choice,
    date: Date,
    email: Email,
    nps: Nps,
    numeric: Numeric,
    currency: Currency,
    emoji: Emoji,
    file: File,
    open: Open,
    starRating: StarRatingInput,
};

export const actionInputs: Record<string, typeof ActionInput> = {
    crmAuth: CrmInput,
    crmApi: CrmInput,
};

export function resolveInputComponent(instruction: InstructionModel): typeof AnswerInput {
    const {question, action} = instruction;

    if (question?.presentation?.type) {
        return questionInputs[question.presentation.type];
    }

    if (question?.type) {
        return questionInputs[question.type];
    }

    if (action?.type && action.type in actionInputs) {
        return actionInputs[action.type];
    }

    throw new Error(
        `Cannot resolve input component for instruction type "${instruction.type}"`,
    );
}

type InstructionProps = Record<string, ActionOrQuestion>;

interface InstructionResolver {
    (instruction: InstructionModel): InstructionProps;
}

const unexpectedInstruction = ({type}: InstructionModel): InstructionProps => {
    throw new Error(`Unexpected instruction "${type}"`);
};

const propResolverByType: Record<InstructionType, InstructionResolver> = {
    'successful-payment': unexpectedInstruction,
    image: unexpectedInstruction,
    message: unexpectedInstruction,
    video: unexpectedInstruction,
    action: (instruction: InstructionModel): InstructionProps => ({action: instruction.action as ActionModel}),
    question: (instruction: InstructionModel): InstructionProps => ({question: instruction.question as QuestionModel}),
    end: unexpectedInstruction,
};

export function resolveInputProps(instruction: InstructionModel): object {
    const resolver = propResolverByType[instruction.type] ?? (() => ({}));
    return resolver(instruction);
}
