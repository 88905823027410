import InstructionAdvancer from '@/interfaces/chat/instruction-advancer.interface';
import {eventCallback} from '@/helpers/event-dispatcher';
import InstructionModel from '@/models/instruction.model';

export default (chat: InstructionAdvancer): EventListener => eventCallback(
    (history: {history: InstructionModel[]}): void => {
        const {history: instructions} = history;

        const lastInstruction = instructions[instructions.length - 1];

        if (['message', 'image', 'action'].includes(lastInstruction?.type ?? '')) {
            chat.nextInstruction(lastInstruction.uuid);
        }
    },
);
