
import AnswerInput from '@/components/answer-input/index.vue';
import {PropType} from 'vue';
import ActionModel from '@/models/action.model';

export default AnswerInput.extend({
    name: 'ActionInput',
    props: {
        action: {
            type: Object as PropType<ActionModel>,
            required: true,
        },
    },
    computed: {
        params(): Record<string, string> {
            return this.action.params ?? {};
        },
        placeholder(): string {
            return this.params.placeholder ?? '';
        },
    },
});
