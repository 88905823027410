/* eslint @typescript-eslint/no-explicit-any: 0 */
import AbbiApi from '@/apis/abbi.api';

export default class ConversationApi extends AbbiApi {
    public static CreateStartedConversation(
        communitySlug: string,
        scriptUuid: string,
        metadata: { [key: string]: string } | null = null,
        externalId: string | null = null,
    ) {
        let url = `${this.url}/api/v2/community/${communitySlug}/chat/event/start/${scriptUuid}/anonymous`;
        if (externalId) {
            url = `${url}?externalId=${externalId}`;
        }
        return ConversationApi.post(url, {metadata} || null);
    }

    public static StartConversation(communitySlug: string, scriptUuid: string, conversationUuid: string) {
        return ConversationApi
            .get(`${this.url}/api/v2/community/${communitySlug}/chat/event/${conversationUuid}/start`);
    }

    public static GetChatHistory(communitySlug: string, scriptUuid: string, conversationUuid: string) {
        return ConversationApi
            .get(`${this.url}/api/v2/community/${communitySlug}/chat/event/${conversationUuid}/history`);
    }

    public static SendConversationEvent(
        communitySlug: string,
        conversationUuid: string,
        instructionUuid: string,
        eventBody: { [key: string]: any },
        externalId: string | null = null,
    ) {
        let url = `${this.url}/api/v2/community/${communitySlug}/chat/event/${conversationUuid}/${eventBody.type}`;
        if (externalId) {
            url = `${url}?externalId=${externalId}`;
        }
        return ConversationApi.post(url, eventBody);
    }

    public static SendConversationReply(
        communitySlug: string,
        conversationUuid: string,
        instructionUuid: string,
        reply: string | number | Record<string, boolean>,
    ) {
        const urlParams = new URLSearchParams(window.location.search);
        const externalId = urlParams.get('externalId');
        return ConversationApi
            .SendConversationEvent(communitySlug, conversationUuid, instructionUuid, {
                instruction: instructionUuid,
                type: 'reply',
                reply,
            }, externalId);
    }

    public static RevertToLastQuestion(
        communitySlug: string,
        conversationUuid: string,
    ) {
        return ConversationApi.SendConversationEvent(communitySlug, conversationUuid, '', {type: 'revert'});
    }

    public static SendFile(
        communitySlug: string,
        conversationUuid: string,
        file: File,
    ) {
        const headers = new Headers();
        headers.append('Accept', 'application/json');

        const formData = new FormData();
        formData.append('file', file);

        // noinspection TypeScriptValidateTypes
        const request = new Request(
            `${this.url}/api/v2/community/${communitySlug}/chat/event/${conversationUuid}/reply`,
            {
                method: 'POST',
                headers,
                body: formData,
            },
        );

        return fetch(request).then(
            (response) => response.json(),
        );
    }

    public static GetChatScriptState(scriptUuid: string) {
        return ConversationApi.get(`${this.url}/chat-script/${scriptUuid}/state`);
    }

    public static GetScriptUuid(communitySlug: string, scriptName: string) {
        return ConversationApi.get(`${this.url}/${communitySlug}/resolve/${scriptName}`);
    }
}
