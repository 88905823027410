import {eventCallback} from '@/helpers/event-dispatcher';
import {CrmAuthType} from '@/helpers/constants';
import InstructionAdvancer from '@/interfaces/chat/instruction-advancer.interface';
import InstructionModel from '@/models/instruction.model';

export default (chat: InstructionAdvancer): EventListener => eventCallback(
    ({action, uuid}: InstructionModel): void => {
        if (Number(action?.params?.crmAuthType) === CrmAuthType.PreAuthenticated) {
            chat.nextInstruction(uuid);
        }
    },
);
