










// @ts-expect-error: No declaration file for module
import { VueReactionEmoji } from 'vue-feedback-reaction';
import Message from '@/components/messages/Message.vue';

const emojiMap = {
    1: 'hate',
    2: 'disappointed',
    3: 'natural',
    4: 'good',
    5: 'excellent',
};

export default Message.extend({
    name: 'EmojiMessage',
    components: {
        VueReactionEmoji,
    },
    computed: {
        reaction() {
            return emojiMap[Number(this.content) as keyof typeof emojiMap];
        },
    },
    data: () => ({
        isActive: true,
        isDisabled: true,
    }),
});
