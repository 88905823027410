import TranslationModel from '@/models/translation.model';

export default class ChoiceModel {
    uuid!: string;

    value = 1;

    translations?: TranslationModel;

    single?: boolean;
}

export function getMessageForLocale(
    self: ChoiceModel,
    locale: string,
    fallbackLocale: string,
): TranslationModel | null {
    if (!self.translations) {
        return null;
    }

    let preferred = null;
    let fallback = null;
    const msg = self.translations as TranslationModel;

    if (msg.locale === locale) {
        preferred = msg;
    } else if (msg.locale === fallbackLocale) {
        fallback = msg;
    }

    return preferred || fallback;
}
