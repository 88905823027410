// eslint-disable-next-line max-classes-per-file
import MessageModel from '@/models/message.model';
import QuestionModel from '@/models/question.model';
import ActionModel from '@/models/action.model';

export type InstructionType = 'question'|'action'|'end'|'message'|'image'|'video'|'successful-payment';

export type ActionOrQuestion = ActionModel|QuestionModel;

export default class InstructionModel {
    uuid = '00000000-0000-0000-0000-000000000000';

    type: InstructionType = 'question';

    order = -1;

    delay = 1;

    can_revert = true;

    metadata: { [key: string]: string } | null | undefined;

    question: QuestionModel | null = null;

    action: ActionModel | null = null;

    translations: MessageModel[] | null = null;
}

export function canBeSkipped({question}: InstructionModel): boolean {
    return question?.skipable ?? false;
}

export class InstructionModelExt {
    static GetMessageForLocale(self: InstructionModel, locale: string, fallbackLocale: string): MessageModel | null {
        if (self.translations == null) return null;
        let preferred = null;
        let fallback = null;
        for (let i = 0; i < self.translations.length; i += 1) {
            const msg = self.translations[i] as MessageModel;
            if (msg.locale === locale) {
                preferred = msg;
                break;
            } else if (msg.locale === fallbackLocale) {
                fallback = msg;
            }
        }

        return preferred || fallback;
    }
}
