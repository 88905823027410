import settings from '@/settings';
import AbbiApi from '@/apis/abbi.api';

import Vue from 'vue';
import VueCookies from 'vue-cookies';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import FlashMessage from '@smartweb/vue-flash-message';
import IFrame from '@/views/dev/IFrame.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import '@/icons';

AbbiApi.setBase(settings.apiBase);

Vue.config.productionTip = false;
Vue.use(FlashMessage);
Vue.use(VueCookies);

if (window.location.href.split('/')[3] === '_test') {
    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(IFrame),
    }).$mount('#app');
} else {
    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount('#app');
}
