/* eslint @typescript-eslint/no-explicit-any: 0 */
type ytCallback = (YT: any) => void;
export default class YoutubeIframeLoaderService {
    private static initialised = false;

    private static initialising = false;

    private static listeners: ytCallback[] = [];

    private static YT: object | null = null;

    public static load(callback: any) {
        this.listeners.push(callback);

        if (YoutubeIframeLoaderService.initialised) {
            YoutubeIframeLoaderService.done();
            return;
        }

        if (this.initialising) return;

        this.initialising = true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line dot-notation
        window['onYouTubeIframeAPIReady'] = () => {
            YoutubeIframeLoaderService.initialised = true;
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.YT = window.YT;
            YoutubeIframeLoaderService.done();
        };

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);
    }

    public static done() {
        while (this.listeners.length) {
            const listener = this.listeners.pop();

            if (listener) {
                listener(this.YT);
            }
        }
    }
}
