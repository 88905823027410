const environment = process.env.VUE_APP_ABBI_ENVIRONMENT || 'local';

const objectStorage = environment === 'local'
    ? 'http://localhost:1024/abbi-insights-local'
    : `https://abbi-insights-${environment}.cdn.cloudbear.nl`;

export default {
    apiBase: (process.env.VUE_APP_ABBI_API_BASE || `${window.location.protocol}//${window.location.hostname}:3000`),
    objectStorage: environment === 'production' ? 'https://abbi-insights.cdn.cloudbear.nl' : objectStorage,

    version: (process.env.VUE_APP_ABBI_VERSION || (environment === 'local' ? 'v.local' : '')),
};
