import Vue from 'vue';
import Vuex from 'vuex';
import createdPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

const securityOpts = {
    encodingType: 'aes',
    encryptionSecret: 'abbi-secret',
    isCompression: true,
    encryptionNamespace: 'abbi-insights~CCP',
};
const localStorage = new SecureLS(securityOpts);

Vue.use(Vuex);

// @todo Remove dependency on store.
class TStore {
    styling: Record<string, Record<string, string>> = {web: {}};
}

export default new Vuex.Store({
    state() {
        return new TStore();
    },
    getters: {
        getWebStyling: (state: TStore) => () => state.styling.web || {},
    },
    mutations: {
        setStyle(state: TStore, styling: Record<string, Record<string, string>>) {
            state.styling = styling;
        },
    },
    actions: {},
    modules: {},
    plugins: [
        createdPersistedState({
            paths: ['conversations'],
            storage: {
                getItem: (key: string) => localStorage.get(key),
                setItem: (key: string, value) => localStorage.set(key, value),
                removeItem: (key: string) => localStorage.remove(key),
            },
        }),
    ],
    strict: process.env.NODE_ENV !== 'production',
});
