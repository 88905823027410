






import Message from '@/components/messages/Message.vue';

export default Message.extend({
    name: 'FileMessage',
    data: () => ({
        previewUrl: '',
        isDisabled: true,
    }),
    mounted() {
        const {rawData: file} = this.message;

        if (file instanceof File) {
            if (file.size === 0) {
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                this.previewUrl = reader.result as string;
                if (this.previewUrl.includes('image/svg+xml')) {
                    this.previewUrl = this.previewUrl.replace('image/svg+xml', 'image/jpeg');
                }
            };
            reader.readAsDataURL(file);

            return;
        }

        this.previewUrl = this.content;
    },
});
