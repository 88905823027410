

























import {currencies} from '@/helpers/currencies';
import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Currency',
    data() {
        return {
            currencyValue: '',
            valid: this.skipable,
        };
    },
    computed: {
        value(): string {
            // eslint-disable-next-line
            // @ts-ignore
            return this.currencyValue;
        },
        currency(): string {
            // eslint-disable-next-line
            // @ts-ignore
            return currencies[this.question.options.currency];
        },
        maximum(): string {
            const {maximum, precision} = this.question.options;
            let maximumText = `${maximum}`;

            if (precision > 0) {
                maximumText += ',';
                maximumText += '0'.repeat(precision);
            }

            return maximumText;
        },
    },
    methods: {
        validateInput() {
            const {options} = this.question;
            this.valid = true;
            const value = Number(this.value.replace(',', '.'));
            if (options.minimum && value < options.minimum) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_LOW_CURRENCY');
                this.valid = false;
            }
            if (options.maximum && value > options.maximum) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_HIGH_CURRENCY');
                this.valid = false;
            }

            let rest = null;
            ['.', ','].forEach((elem) => {
                if (this.value.indexOf(elem) !== -1) {
                    // eslint-disable-next-line prefer-destructuring
                    rest = this.value.split(elem)[1];
                }
            });
            // eslint-disable-next-line
            // @ts-ignore
            if ((options.precision === 0 && (rest === '' || rest !== null)) || (rest && rest.length > options.precision)) {
                this.$emit('error', 'ERROR.VALIDATION.PRECISION_CURRENCY');
                this.valid = false;
            }
            this.$emit('valid', this.valid);
        },
        inputChanged($e: KeyboardEvent): void {
            if ($e.key === 'Enter') {
                this.$nextTick(() => this.$emit('submit'));
                return;
            }

            const keyTest = /^[0-9\\.,-]$/;
            if (!keyTest.test($e.key)) {
                $e.preventDefault();
                $e.stopImmediatePropagation();
                return;
            }

            const test = /^-?[0-9]*[,\\.]?[0-9]*$/;
            if (!test.test(`${this.currencyValue}${$e.key}`)) {
                $e.preventDefault();
                $e.stopImmediatePropagation();
            }
        },
    },
});
