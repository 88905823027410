/* eslint @typescript-eslint/no-explicit-any: 0 */
import AnswerModel from '@/models/answer.model';
import InstructionModel from '@/models/instruction.model';

export default class ReplyModel {
    instruction: InstructionModel;

    displayAnswer: AnswerModel;

    data: any;

    constructor(
        instruction: InstructionModel,
        displayAnswer: AnswerModel,
        data: any,
    ) {
        this.instruction = instruction;
        this.displayAnswer = displayAnswer;
        this.data = data;
    }
}
