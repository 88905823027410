















// @ts-nocheck
import { VueFeedbackReaction } from 'vue-feedback-reaction';
import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Emoji',
    components: {
        VueFeedbackReaction,
    },
    data: () => ({
        emoji: '',
    }),
    methods: {
        handleEmojiPick() {
            this.$emit('valid', true);
        },
    },
});
