
import Chat from '@/views/Chat.vue';
import WindowMessageModel from '@/models/functional/window-message.model';
import InstructionModel from '@/models/instruction.model';
import AnswerModel from '@/models/answer.model';
import ChatService from '@/services/chat.service';
import createChat from '@/factories/chat.factory';

interface Stylesheet {
    [key: string]: string;
}

interface ChatState {
    date: string | Date;
    isTyping: boolean;
    instructions: InstructionModel[];
    answers: AnswerModel[];
    lastAnswered: boolean;
    completed: number;
    validationError: string | null;
    valid: boolean;
    canGoBack: boolean;
}

export default Chat.extend({
    name: 'Preview',
    data(): ChatState {
        return {
            answers: [],
            date: new Date(),
            instructions: [],
            isTyping: false,
            lastAnswered: false,
            completed: 0,
            validationError: null,
            valid: true,
            canGoBack: false,
        };
    },
    computed: {
        canRevert(): boolean {
            return this.canGoBack;
        },
        fontSourceGoogleEnabled(): boolean {
            return this.$store.state.styling?.web?.font_source_google === '1' ?? false;
        },
        customFontUrl(): string {
            return this.$store.state.styling?.web?.['body_font-file'] ?? '';
        },
    },
    created() {
        window.addEventListener('message', this.messageHandler);
    },
    destroyed() {
        window.removeEventListener('message', this.messageHandler);
    },
    watch: {
        completed(newCompleted: number): void {
            this.$emit('progress', newCompleted);
        },
        customFontUrl(url: string): void {
            if (this.fontSourceGoogleEnabled) return;

            const {style} = document.documentElement;

            const ABBI_CUSTOM_FONT_FAMILY = 'ABBI Custom-er Font';
            this.loadFontFromUrl(ABBI_CUSTOM_FONT_FAMILY, url);
            style.setProperty('--body-font-family', `'${ABBI_CUSTOM_FONT_FAMILY}'`);
        },
        fontSourceGoogleEnabled(enabled: boolean): void {
            const {style} = document.documentElement;

            if (enabled) {
                style.setProperty('--body-font-family', '');
            }
        },
    },
    methods: {
        messageHandler(event: MessageEvent) {
            const message = event.data as WindowMessageModel;
            const handlerMap = {
                setTitle: this.setTitle,
                setStyle: this.setStyle,
                updateStyle: this.updateStyle,
                setState: this.setState,
            } as Record<string, (payload: object) => void>;
            const handler = handlerMap[message.type] ?? null;

            if (handler !== null) {
                handler(message.content);
            }
        },
        setTitle(payload: {title: string}): void {
            document.title = payload.title;
        },
        setStyle(payload: {stylesheet: Stylesheet}): void {
            this.updateStyle(payload.stylesheet);
        },
        updateStyle(payload: Stylesheet): void {
            const {style} = document.documentElement;
            const webStyling = this.$store.getters.getWebStyling;
            const styling = JSON.parse(JSON.stringify(this.$store.state.styling)) || {};

            Object.assign(
                styling,
                {
                    web: {
                        ...webStyling || styling.web || {},
                        ...payload,
                    },
                },
            );
            this.$store.commit('setStyle', styling);

            Object.keys(payload).forEach((property: string) => {
                this.handlePropertyUpdated(property, payload[property]);
                style.setProperty(
                    `--${property.replaceAll('_', '-')}`,
                    payload[property],
                );
            });
        },
        loadFontFromUrl(name: string, url: string) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2304
            const font = new FontFace(name, `url(${url})`);
            font.load()
                .then((loadedFont: any) => {
                    (document as any).fonts.add(loadedFont);
                });
        },
        loadStylesheetFromUrl(url: string) {
            const documentHead = document.head;
            const link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = url;
            documentHead.appendChild(link);
        },
        loadGoogleFontFromFamilyNameAndWeight(name: string, weight = '100;200;300;400;500;600;700;800;900;950') {
            const url = `https://fonts.googleapis.com/css2?family=${name}:wght@${weight}&display=swap`;
            this.loadStylesheetFromUrl(url);
        },
        handlePropertyUpdated(property: string, value: string) {
            if (property.endsWith('background-image')) {
                return;
            }

            if (property.endsWith('google_font-family')) {
                this.loadGoogleFontFromFamilyNameAndWeight(value);
            }
        },
        setState(payload: ChatState): void {
            Object.assign(this, payload);
        },
        async createChat(): Promise<ChatService> {
            return createChat('abbi', 'preview');
        },
    },
});
