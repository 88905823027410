






/* eslint @typescript-eslint/no-explicit-any: 0 */
import YoutubeIframeLoaderService from '@/services/YoutubeIframeLoader.service';
import Message from '@/components/messages/Message.vue';

interface Query {[key: string]: string}

export default Message.extend({
    name: 'VidMessage',
    props: {
        shouldPlay: Boolean,
    },
    data() {
        return {
            player: null as any,
            autoplay: false,
            waitForFinish: false,
            ready: false,
            finished: false,
        };
    },
    computed: {
        video(): string {
            return this.content.replace(
                /<span class="vid-msg">.*<\/span>$/g,
                '',
            );
        },
    },
    methods: {
        parseQuery(url: string): Query {
            const query = {} as Query;

            (new URL(url)).searchParams.forEach((value, key) => {
                query[key] = value;
            });

            return query;
        },
        videoReady() {
            this.ready = true;

            if (this.autoplay && this.shouldPlay) {
                this.player.playVideo();
            }

            if (this.shouldPlay && !this.waitForFinish) {
                this.$emit('finished');
            }
        },
        onVideoStateChange($event: Record<string, any>&Event) {
            const {YT} = window as Record<string, any>;
            const {PAUSED, ENDED} = YT.PlayerState;

            if (this.waitForFinish
                && !this.finished
                && [PAUSED, ENDED].includes($event.data)
            ) {
                this.finished = true;
                this.$emit('finished');
            }
        },
    },
    mounted() {
        const messageContainer = document.querySelector(`[data-message="${this.message.uuid}"]`);

        this.autoplay = this.content.indexOf('will-autostart') > -1;
        this.waitForFinish = this.content.indexOf('will-wait-for-end') > -1;

        const video = messageContainer?.querySelector('.video-message-content div[data-vid-url]') as HTMLElement;
        const {v: videoId} = this.parseQuery(video?.dataset.vidUrl ?? '');

        YoutubeIframeLoaderService.load((YT: any) => {
            // https://developers.google.com/youtube/iframe_api_reference
            this.player = new YT.Player(video, {
                autoplay: this.autoplay,
                disablekb: 1,
                controls: 1,
                fs: 0,
                videoId,
                width: 480,
                height: 270,
                events: {
                    onReady: this.videoReady,
                    onStateChange: this.onVideoStateChange,
                },
            });
        });
    },
});
