import EventDispatcher, {eventCallback} from '@/helpers/event-dispatcher';
import ChatService from '@/services/chat.service';
import handlersDefault from '@/events/chat/handlers.default';

export interface EventHandlerConfig {
    type: string;
    handler: Function;
}

export default function createChat(
    communitySlug: string,
    scriptUuid: string,
    eventHandlers: EventHandlerConfig[] = [],
): ChatService {
    const chat = new ChatService(
        communitySlug,
        scriptUuid,
        new EventDispatcher(),
    );

    handlersDefault.forEach(({type, factory}) => {
        chat.on(type, factory(chat));
    });

    eventHandlers.forEach(({type, handler}: EventHandlerConfig) => {
        chat.on(type, eventCallback(handler));
    });

    return chat;
}
