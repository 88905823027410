
import Vue from 'vue';

export default Vue.extend({
    name: 'AnswerInput',
    methods: {
        onFocus(): void {
            this.$emit('focus');
        },
        onBlur(): void {
            this.$emit('blur');
        },
        onChange(): void {
            this.$emit('change');
        },
    },
});
