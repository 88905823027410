/* eslint @typescript-eslint/no-explicit-any: 0 */
import QuestionModel, {QuestionType} from '@/models/question.model';

interface QuestionDecoder {
    (question: QuestionModel, encoded: any): any;
}

const passThroughDecoder = (question: QuestionModel, encoded: any): any => encoded;

const questionDecoders: Record<QuestionType, QuestionDecoder> = {
    date: (question: QuestionModel, encoded: {date: string}): string => encoded.date,
    multiple: passThroughDecoder,
    file: passThroughDecoder,
    open: passThroughDecoder,
    email: passThroughDecoder,
    numeric: passThroughDecoder,
    nps: passThroughDecoder,
    emoji: passThroughDecoder,
    choice: passThroughDecoder,
    currency: passThroughDecoder,
    starRating: passThroughDecoder,
};

export default function decodeHistoricAnswer(
    question: QuestionModel,
    encoded: any,
): any {
    let decoded = encoded;

    if (question.type) {
        decoded = questionDecoders[question.type](question, encoded);
    }

    return decoded;
}
