




import Message from '@/components/messages/Message.vue';

export default Message.extend({
    name: 'ImgMessage',
});
