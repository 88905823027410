/* eslint @typescript-eslint/no-explicit-any: 0 */
import InstructionModel from '@/models/instruction.model';
import AnswerModel from '@/models/answer.model';
import QuestionModel, {QuestionType} from '@/models/question.model';
import ChoiceModel, {getMessageForLocale} from '@/models/choice.model';
import ActionModel, {ActionType} from '@/models/action.model';

const stringFormatter = (value: any): string => String(value);
const numberFormatter = (value: any): string => String(Number(value));
const unexpectedFormatter = () => {
    throw new Error('Unexpected answer type');
};

interface ActionFormatter {
    (value: any, action: ActionModel): any;
}

interface QuestionFormatter {
    (value: any, question: QuestionModel): any;
}

const actionFormatters: Record<ActionType, ActionFormatter> = {
    crmAuth: stringFormatter,
    crmApi: stringFormatter,
    payment: unexpectedFormatter,
    send2con: unexpectedFormatter,
    send2api: unexpectedFormatter,
    score: unexpectedFormatter,
    end: unexpectedFormatter,
};

const questionFormatters: Record<QuestionType, QuestionFormatter> = {
    numeric: numberFormatter,
    nps: numberFormatter,
    open: stringFormatter,
    email: stringFormatter,
    currency: stringFormatter,
    emoji: numberFormatter,
    file: (file: File | string | null): File | string => (file === null ? '' : file),
    date(value: any): string {
        const [date] = String(value).split(/(T|\s)/);
        return date;
    },
    choice(value: any, question: QuestionModel): string {
        const chosen = Number(value);
        const choices = question.choices ?? [] as ChoiceModel[];

        return getMessageForLocale(
            choices.find(
                (choice: ChoiceModel) => choice.value === chosen,
            ) as ChoiceModel,
            'en',
            'en',
        )?.content ?? '';
    },
    multiple(value: Record<string, any>, question: QuestionModel): string {
        const choices = question.choices ?? [] as ChoiceModel[];
        return choices.reduce(
            (carry: string, choice: ChoiceModel) => {
                let formatted = carry;

                if (value[choice.uuid]) {
                    if (formatted.length) {
                        formatted += '<br />';
                    }

                    formatted += getMessageForLocale(
                        choice,
                        'en',
                        'en',
                    )?.content;
                }

                return formatted;
            },
            '',
        );
    },
    starRating: numberFormatter,
};

export default function createAnswer(
    instruction: InstructionModel,
    value: any,
): AnswerModel {
    const {question, action} = instruction;
    const answer = new AnswerModel(instruction.uuid);

    if (question?.type && questionFormatters[question.type]) {
        answer.value = questionFormatters[question.type](value, question);
    }

    if (action && actionFormatters[action.type]) {
        answer.value = actionFormatters[action.type](value, action);
    }

    return answer;
}
