export function getAppleMobileOsVersion(userAgent: string = navigator.userAgent): number|null {
    const {version} = /iP\w{2,4};[\s\w]+OS\s(?<version>\d{1,2})/.exec(userAgent)?.groups || {version: null};
    return Number(version);
}

interface OsVersion {
    major: number;
}

interface UserAgentInfo {
    ios?: OsVersion;
    iPhone: boolean;
}

let ua: null | UserAgentInfo = null;

export function createUserAgentInfo(navigator: Navigator): UserAgentInfo {
    const ios = getAppleMobileOsVersion(navigator.userAgent);

    return {
        ios: ios ? {major: ios} : undefined,
        iPhone: navigator.userAgent.includes('iPhone'),
    };
}

export default function getUserAgentInfo(): UserAgentInfo {
    if (ua === null) {
        ua = createUserAgentInfo(navigator);
    }

    return ua;
}
