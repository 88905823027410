import ActionModel from '@/models/action.model';
import CrmNormalizer, {normalizers} from '@/normalizer/crm.normalizer';
import {CrmAuthType, ValidationType} from '@/helpers/constants';

function createApiNormalizer(validation: ValidationType): CrmNormalizer {
    return normalizers[validation];
}

function createAuthNormalizer(auth: CrmAuthType, validation: ValidationType): CrmNormalizer {
    if (auth === CrmAuthType.Anonymous) {
        if (validation === ValidationType.NotEmpty) {
            throw new Error('Must use explicit validation type for anonymous authentication.');
        }

        return normalizers[validation];
    }

    if (auth === CrmAuthType.PreAuthenticated) {
        return normalizers[ValidationType.NotEmpty];
    }

    if (auth === CrmAuthType.Authenticated) {
        return normalizers[ValidationType.Numeric];
    }

    throw new Error(`Unexpected authentication type "${auth}"`);
}

export default function createCrmNormalizer(action: ActionModel): CrmNormalizer {
    const {params} = action;

    if (!params) {
        throw new Error('Action is not properly configured.');
    }

    if (!('validation' in params)) {
        throw new Error('Cannot create CRM normalizer without validation type.');
    }

    const {validation} = params;

    if (action.type === 'crmApi') {
        return createApiNormalizer(validation as ValidationType);
    }

    if (action.type !== 'crmAuth') {
        throw new Error(`Unexpected action type "${action.type}"`);
    }

    if (!('crmAuthType' in params)) {
        throw new Error(
            'Cannot create CRM authentication normalizer with unknown authentication type.',
        );
    }

    return createAuthNormalizer(
        params.crmAuthType as CrmAuthType,
        validation as ValidationType,
    );
}
