
















































































































































































































































































































































import {library} from '@fortawesome/fontawesome-svg-core';
import vue from 'vue';

interface Icon {
    name: string;
    aliases: string[];
}

export default vue.extend({
    data() {
        return {
            view: 'grid',
            icons: {} as {
                [key: string]: Icon;
            },
            preferredIcons: [
                'bars',
                'backspace',
                'times',
                'paperplane',
                'paperclip',
                'file',
                'camera',
                'caret-down',
                'caret-up',
                'search',
                'up-down',
            ] as string[],
            hueStep: '15',
            lightness: 50,
            saturation: 90,
            alpha: 100,
            fontSize: 1.5,
        };
    },
    computed: {
        hues(): number[] {
            const hues = [];
            const step = parseInt((this.hueStep as string), 10);

            for (let angle = 0; angle < 360; angle += step) {
                hues.push(angle);
            }

            return hues;
        },
        iconStyle(): object {
            return {
                'font-size': `${this.fontSize}em`,
            };
        },
        pieStyle(): string {
            const step = parseInt(this.hueStep, 10);
            const stops = [];
            const variables = [`--slice-angle: ${this.hueStep}deg;`];

            for (let angle = 0; angle < 360; angle += step) {
                stops.push(`hsl(${angle}deg 100% 50% / 100%) ${angle}deg`);
                stops.push(`hsl(${angle}deg 100% 50% / 100%) ${angle + step}deg`);
            }

            variables.push(`--pie-gradient: conic-gradient(${stops.join(', ')});`);

            return variables.join(' ');
        },
    },
    mounted() {
        const {definitions} = library as unknown as {
            definitions: {
                fas: {
                    [key: string]: Array<number|string|string[]>;
                };
            };
        };
        const {fas} = definitions;

        this.icons = {};
        Object.keys(fas).forEach((name: string) => {
            const aliases = Array
                .from(fas[name][2] as [])
                .filter(
                    (alias: string|number) => (typeof alias === 'string'),
                ) as string[];

            aliases.push(name);

            let icon = aliases.reduce(
                (carry: null|Icon, alias: string) => (carry ?? this.icons[alias] ?? null),
                null,
            );

            if (icon === null) {
                this.icons[name] = {name, aliases};
                icon = this.icons[name];
            }

            icon.aliases = aliases.filter(
                (alias: string) => alias !== icon?.name,
            );
        });
    },
    methods: {
        getPreferredIconName(icon: Icon): string {
            return icon.aliases.reduce(
                (carry: null|string, alias: string) => (
                    this.preferredIcons.includes(alias) ? alias : carry
                ),
                null,
            ) ?? icon.name;
        },
        getIconStyle(
            hue: number,
            saturation: number|null = null,
            lightness: number|null = null,
        ): object {
            return {
                ...this.iconStyle,
                color: `hsl(
                    ${hue}deg
                    ${saturation ?? this.saturation}%
                    ${lightness ?? this.lightness}%
                    / ${this.alpha}%
                )`,
            };
        },
        getContrastingIconStyle(
            hue: number,
            saturation: number|null = null,
            lightness: number|null = null,
        ): object {
            return {
                ...this.getIconStyle(hue),
                'background-color': `hsl(
                    calc(${hue}deg + .5turn)
                    ${saturation ?? this.saturation}%
                    ${lightness ?? this.lightness}%
                    / ${this.alpha}%
                )`,
            };
        },
    },
});
