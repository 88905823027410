



















import QuestionInput from '@/components/answer-input/QuestionInput.vue';
import {FaRating} from 'vue-rate-it';
import Star from 'vue-rate-it/glyphs/star';
import getStyleProperty from '@/helpers/style';

export default QuestionInput.extend({
    name: 'StarRatingInput',
    data() {
        return {
            value: null,
            max: 5 as number,
            increment: 1 as number,
            glyph: '',
        };
    },
    watch: {
        value(): void {
            this.$emit('input', this.value);

            if ((this.value ?? 0) > 0) {
                this.validateInput();
            }
        },
    },
    components: {FaRating},
    methods: {
        validateInput(): void {
            const value = Number(this.value);
            let valid = true;

            const {
                max,
            } = this;

            if (valid && value < 0.5) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_LOW');
                valid = false;
            }

            if (valid && max < value) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_HIGH');
                valid = false;
            }

            this.$emit('valid', valid);
        },
    },
    computed: {
        inactiveColor() {
            return getStyleProperty(this.$store.state.styling?.web, 'star_rating_inactive-color', '#D8D8D8FF', false);
        },
        activeColor() {
            return getStyleProperty(this.$store.state.styling?.web, 'star_rating_active-color', '#FED055FF', false);
        },
        borderColor() {
            return getStyleProperty(this.$store.state.styling?.web, 'star_rating_border-color', '#000000FF', false);
        },
        borderWidth() {
            return parseInt(getStyleProperty(this.$store.state.styling?.web, 'star_rating_border-width', '2', false), 10);
        },
        itemSize(): number {
            return 42 - this.borderWidth;
        },
    },
    created() {
        this.glyph = Star;
    },
    mounted() {
        this.value = this.question.options?.initialValue ?? 0;
        this.increment = this.question.options?.increment ?? 1;
        this.max = this.question.options?.max ?? 5;
    },
});
