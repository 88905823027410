
import AnswerInput from '@/components/answer-input/index.vue';
import {PropType} from 'vue';
import QuestionModel from '@/models/question.model';

export default AnswerInput.extend({
    name: 'QuestionInput',
    props: {
        question: {
            type: Object as PropType<QuestionModel>,
            required: true,
        },
    },
    computed: {
        placeholder(): string {
            return this.question.placeholder ?? '';
        },
        skipable(): boolean {
            return this.question.skipable === true;
        },
    },
});
