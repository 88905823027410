


















import {debounce} from 'debounce';
import QuestionInput from '@/components/answer-input/QuestionInput.vue';

export default QuestionInput.extend({
    name: 'Email',
    data() {
        return {
            type: navigator.userAgent.includes('Safari') ? 'text' : 'search',
            email: '',
            valid: this.skipable,
            update: null as null | Function,
        };
    },
    mounted(): void {
        this.update = debounce(this.onUpdate.bind(this), 1000);
    },
    watch: {
        email(email: string): void {
            this.valid = false;
            this.$emit('error', '');
            this.validateEmail(email)
                .then(() => {
                    this.valid = true;
                    this.$emit('error', '');
                })
                .catch(() => {
                    this.valid = false;
                });

            if (this.update) {
                this.update(email);
            }
        },
        valid(newVal: boolean): void {
            this.$emit('valid', newVal);
        },
    },
    methods: {
        onUpdate(email: string): void {
            this.process(email);
        },
        async process(email: string): Promise<boolean> {
            this.valid = await this.validateEmail(email)
                .then(() => true)
                .catch((error: Error) => {
                    this.$emit('error', error.message);
                    return false;
                });

            return this.valid;
        },
        async onSubmit() {
            if (this.valid || await this.process(this.email)) {
                this.$emit('submitForm');
            }
        },
        validateEmail(email: string): Promise<string> {
            return new Promise((resolve, reject) => {
                if (!/\S+@\S+\.\S+/.test(email)) {
                    reject(new Error('ERROR.VALIDATION.INVALID_EMAIL'));
                    return;
                }

                resolve(email);
            });
        },
    },
});
