import {ValidationType} from '@/helpers/constants';

interface CrmNormalizer {
    (value: string): string;
}

export const emailNormalizer: CrmNormalizer = (value: string): string => {
    const email = value.trim();

    if (!/\S+@\S+\.\S+/.test(email)) {
        throw new Error('ERROR.VALIDATION.INVALID_EMAIL');
    }

    return email;
};
export const numericNormalizer: CrmNormalizer = (value: string): string => {
    const number = value.trim();

    if (!/^[0-9]+$/.test(number)) {
        throw new Error('ERROR.VALIDATION.NUMERIC');
    }

    return number;
};

export const nonEmptyNormalizer: CrmNormalizer = (value: string): string => {
    const subject = value.trim();

    if (subject.length === 0) {
        throw new Error('ERROR.VALIDATION.NOT_EMPTY');
    }

    return subject;
};

export const uuidNormalizer: CrmNormalizer = (value: string): string => {
    const uuid = value.trim();

    if (!/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(uuid)) {
        throw new Error('ERROR.VALIDATION.VALUE');
    }

    return uuid;
};

export const normalizers: Record<ValidationType, CrmNormalizer> = {
    [ValidationType.Email]: emailNormalizer,
    [ValidationType.Uuid]: uuidNormalizer,
    [ValidationType.Numeric]: numericNormalizer,
    [ValidationType.NotEmpty]: nonEmptyNormalizer,
};

export default CrmNormalizer;
