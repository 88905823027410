import ImgMessage from '@/components/messages/ImgMessage.vue';
import EmojiMessage from '@/components/messages/EmojiMessage.vue';
import FileMessage from '@/components/messages/FileMessage.vue';
import VidMessage from '@/components/messages/VidMessage.vue';
import Message from '@/components/messages/Message.vue';
import ChatMessageModel, {MessageType} from '@/models/chat-message.model';
import StarRatingMessage from '@/components/messages/StarRatingMessage.vue';

export const messageComponents: Record<MessageType, typeof Message> = {
    image: ImgMessage,
    emoji: EmojiMessage,
    file: FileMessage,
    video: VidMessage,
    starRating: StarRatingMessage,
};

export function resolveMessageComponent(message: ChatMessageModel): typeof Message {
    return messageComponents[message.type] ?? Message;
}

export function resolveMessageProps(message: ChatMessageModel, isLatest: boolean): object {
    let additionalProps = {};

    if (message.type === 'video') {
        additionalProps = {
            shouldPlay: isLatest,
        };
    }

    return {
        message,
        ...additionalProps,
    };
}
