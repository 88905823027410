




















import vue from 'vue';
import ChatActionButton from '@/components/chat/ChatActionButton.vue';

export default vue.extend({
    components: {ChatActionButton},
    props: {
        submit: {
            type: Boolean,
            required: true,
        },
        revert: {
            type: Boolean,
            required: true,
        },
    },
});
