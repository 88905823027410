
































import QuestionInput from '@/components/answer-input/QuestionInput.vue';
import {isBrightColor} from '@/helpers/color-utilities';

export default QuestionInput.extend({
    name: 'Open',
    data() {
        return {
            valid: this.skipable,
            multiline: true,
            text: '',
        };
    },
    watch: {
        text(value) {
            this.validateInput(value);
        },
    },
    computed: {
        inputControlBackgroundColor(): string {
            return this.$store.state.styling?.web?.['input-control_background-color'] ?? '#A6A6A6';
        },
        inputControlbackgroundContrastColor(): string {
            const isBright = isBrightColor(this.inputControlBackgroundColor);
            return isBright ? '#00000080' : '#FFFFFF80';
        },
    },
    methods: {
        onPressEnter() {
            this.validateInput(this.text);
            if (this.valid) {
                this.$emit('submitForm');
            }
        },
        validateInput(input: string) {
            const {options} = this.question;
            this.valid = true;
            if (options.minimum && input.trim().length < options.minimum) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_SHORT');
                this.valid = false;
            }
            if (options.maximum && input.length > options.maximum) {
                this.$emit('error', 'ERROR_VALIDATION.TOO_LONG');
                this.valid = false;
            }
            this.$emit('valid', this.valid);
        },
    },
    created() {
        this.multiline = !(this.question.options.boxSize === 'singleline');
    },
});
