












































import QuestionInput from '@/components/answer-input/QuestionInput.vue';
import Slider from '@/components/answer-input/control/Slider.vue';
import QuestionModel from '@/models/question.model';

export default QuestionInput.extend({
    name: 'Numeric',
    components: {Slider},
    data() {
        return {
            data: '',
            valid: this.skipable,
        };
    },
    computed: {
        value(): number {
            return parseFloat(this.data.replace(',', '.') || '0');
        },
        slider(): boolean {
            return this.question.options?.hasSlider || false;
        },
        sliderProps(): object {
            const {
                lowerBoundLabel: minLabel,
                upperBoundLabel: maxLabel,
                hasLabel,
            } = this.question.options;

            return this.slider && hasLabel
                ? {minLabel, maxLabel}
                : {};
        },
    },
    methods: {
        validateInput() {
            const {options} = this.question;
            this.valid = true;
            if (options.minimum && this.value < Number.parseInt(options.minimum, 10)) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_LOW');
                this.valid = false;
            }
            if (options.maximum && this.value > Number.parseInt(options.maximum, 10)) {
                this.$emit('error', 'ERROR.VALIDATION.TOO_HIGH');
                this.valid = false;
            }

            if (!this.question.options?.hasSlider) {
                let rest = null;
                ['.', ','].forEach((elem) => {
                    if (this.data.indexOf(elem) !== -1) {
                        // eslint-disable-next-line prefer-destructuring
                        rest = this.data.split(elem)[1];
                    }
                });
                // eslint-disable-next-line
                // @ts-ignore
                if ((options.precision === 0 && (rest === '' || rest !== null)) || (rest && rest.length > options.precision)) {
                    this.$emit('error', 'ERROR.VALIDATION.PRECISION');
                    this.valid = false;
                }
            }
            this.$emit('valid', this.valid);
        },
        inputChanged($e: KeyboardEvent): void {
            if ($e.key === 'Enter') {
                this.$nextTick(() => this.$emit('submit'));
                return;
            }

            const keyTest = /^[0-9\\.,-]$/;
            if (!keyTest.test($e.key)) {
                $e.preventDefault();
                $e.stopImmediatePropagation();
                return;
            }

            const test = /^-?[0-9]*[,\\.]?[0-9]*$/;
            if (!test.test(`${this.data}${$e.key}`)) {
                $e.preventDefault();
                $e.stopImmediatePropagation();
            }
        },
        calculateStartValue(question: QuestionModel): string {
            const {maximum, minimum, stepSize} = question.options;
            const [, fraction] = `${stepSize}.`.split('.');
            const fractionDigits = fraction.length;
            const roundFactor = 10 ** fractionDigits;
            const middle = ((maximum - minimum) / 2) + minimum;
            const middleValue = Math.ceil(middle * roundFactor) / roundFactor;
            return middleValue.toFixed(fractionDigits);
        },
    },
    mounted() {
        if (this.slider) {
            this.data = this.calculateStartValue(this.question);
            this.validateInput();
        }
    },
});
