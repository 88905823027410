import actionCrmAuthExpiredHandlerFactory from '@/events/chat/action.crmAuthExpired.handler.factory';
import ActionCrmAuthPreAuthenticatedHandlerFactory from '@/events/chat/action.crmAuthPreAuthenticated.handler.factory';
import chatNextInstructionHandlerFactory from '@/events/chat/chat.nextInstruction.handler.factory';
import instructionActionPaymentRedirectHandlerFactory from '@/events/chat/instruction.action.payment.redirect.handler.factory';
import instructionActionEndRedirectHandlerFactory from '@/events/chat/instruction.action.end.redirect.handler.factory';
import chatHaltHandlerFactory from '@/events/chat/chat.halt.handler.factory';
import InstructionAdvancer from '@/interfaces/chat/instruction-advancer.interface';
import InstructionHalter from '@/interfaces/chat/instruction-halter.interface';
import chatHistoryLoadedHandlerFactory from '@/events/chat/chat.historyLoaded.handler.factory';

export type EventListenerFactory = () => EventListener;
export type AdvancerListenerFactory = (chat: InstructionAdvancer) => EventListener;
export type HalterListenerFactory = (chat: InstructionHalter) => EventListener;

export interface DefaultChatHandlerConfig {
    type: string;

    factory: EventListenerFactory | AdvancerListenerFactory | HalterListenerFactory;
}

const handlers: DefaultChatHandlerConfig[] = [
    {
        type: 'before-action:crmAuth',
        factory: actionCrmAuthExpiredHandlerFactory,
    },
    {
        type: 'action-instruction:crmAuth',
        factory: ActionCrmAuthPreAuthenticatedHandlerFactory,
    },
    {
        type: 'action-instruction:payment',
        factory: instructionActionPaymentRedirectHandlerFactory,
    },
    {
        type: 'action-instruction:end',
        factory: instructionActionEndRedirectHandlerFactory,
    },
    {
        type: 'history-loaded',
        factory: chatHistoryLoadedHandlerFactory,
    },
];

// Configure the events that automatically request a next instruction.
[
    'instruction:message',
    'instruction:image',
    'action-instruction:score',
].forEach((type) => {
    handlers.push({
        type,
        factory: chatNextInstructionHandlerFactory,
    });
});

// Configure the events that mark the chat as halted (expecting user input)
[
    'instruction:question',
    'instruction:end',
    'instruction:video',
    'action:crmAuth',
    'action:crmApi',
].forEach((type) => {
    handlers.push({
        type,
        factory: chatHaltHandlerFactory,
    });
});

export default handlers;
