





































import vue from 'vue';
import SideMenu from '@/components/structure/SideMenu.vue';
import CommunityService from '@/services/community.service';
import Loader from '@/views/Loader.vue';
import {Route} from 'vue-router';
import {applyLuminance} from '@/helpers/color-utilities';
import features from '@/helpers/features';
import {
    PatchedNavigator,
    VirtualKeyboard,
} from '@/helpers/dom';

import getUserAgentInfo from '@/helpers/user-agent';

const ua = getUserAgentInfo();

export default vue.extend({
    name: 'App',
    components: {Loader, SideMenu},
    methods: {
        openSideMenu() {
            this.visible = true;
        },
        closeSideMenu() {
            this.visible = false;
        },
        resizeEventHandler() {
            if (window.innerWidth > 800) {
                this.visible = false;
            }
        },
        handleLoader(loading: boolean) {
            this.isLoading = loading;
        },
        handleProgress(newProgression: number) {
            const pageEl = this.$refs.page as HTMLDivElement;
            pageEl.style.setProperty('--progress', `${newProgression * 100}%`);
        },
        handleHeaderExpandedState(expanded: boolean): void {
            const header = this.$refs.header as HTMLElement;
            header.style.setProperty('--header-expanded', expanded ? '1' : '0');
        },
        expandHeader(): void {
            this.determineHeaderExpansionState();
        },
        collapseHeader(): void {
            this.handleHeaderExpandedState(false);
        },
        determineHeaderExpansionState(): void {
            const { style } = document.documentElement;

            const headerBackgroundImageValue = style.getPropertyValue('--header-background-image');
            const headerBackgroundSizeValue = style.getPropertyValue('--header-background-size');

            const shouldCollapseHeader = (headerBackgroundSizeValue !== 'cover' || headerBackgroundImageValue === '');

            this.handleHeaderExpandedState(!shouldCollapseHeader);
        },
        handleHeaderLuminocityChange(value: string): void {
            if (!value) {
                return;
            }

            applyLuminance(
                document.documentElement,
                value,
                '--header-hamburger-menu-contrast-color',
                'white',
                'black',
            );
        },

        async prepareCommunity() {
            const {community} = this.$router.currentRoute.params;

            function handleProperty(property: string, value: string) {
                if (property.endsWith('background-image')) {
                    return;
                }

                const {style} = document.documentElement;

                style.setProperty(
                    `--${property.replaceAll('_', '-')}`,
                    value,
                );
            }

            if (community !== undefined) {
                const styling = await CommunityService.GetStylingForCommunity();
                if (styling !== null) {
                    if (styling.community !== undefined && styling.community['custom-stylesheet'] !== undefined) {
                        const stylesheet = document.createElement('link') as HTMLLinkElement;
                        stylesheet.href = styling.community['custom-stylesheet'];
                        stylesheet.rel = 'stylesheet';
                        stylesheet.type = 'text/css';
                        document.head.appendChild(stylesheet);
                    }

                    this.$store.commit('setStyle', styling);
                    this.styled = true;

                    Object.keys(styling.web || {}).forEach((property: string) => {
                        handleProperty(property, styling.web[property]);
                    });
                }
            }
        },
        getStyle(property: string): string {
            const {style} = document.documentElement;
            return style.getPropertyValue(property);
        },
        setStyle(property: string, value: string): void {
            const {style} = document.documentElement;
            style.setProperty(property, value);
        },
        handleKeyboardHeight(height: number): void {
            this.setStyle('--keyboard-height', `${height}px`);
        },
        handleKeyboardGeometryChange(event: Event): void {
            const kbd = event.target as VirtualKeyboard;
            const {height, y} = kbd.boundingRect;
            this.handleKeyboardHeight(height + y);
        },
        registerVirtualKeyboard(
            overlay: boolean,
            {virtualKeyboard}: PatchedNavigator,
        ): void {
            if (!virtualKeyboard) {
                return;
            }

            const kbd = virtualKeyboard;
            kbd.overlaysContent = overlay;

            if (kbd.overlaysContent) {
                kbd.addEventListener('geometrychange', this.handleKeyboardGeometryChange);
                return;
            }

            kbd.removeEventListener('geometrychange', this.handleKeyboardGeometryChange);
        },
    },
    watch: {
        $route: {
            deep: true,
            handler(to: Route, from: Route) {
                const fromComm = from?.params?.community;
                const toComm = to?.params?.community;

                if (toComm !== fromComm) {
                    this.styled = false;
                    this.prepareCommunity();
                }
            },
        },
        headerColor: {
            deep: true,
            handler(value: string) {
                this.handleHeaderLuminocityChange(value);
            },
        },
        headerBackgroundImage() {
            this.determineHeaderExpansionState();
        },
        headerBackgroundSize() {
            this.determineHeaderExpansionState();
        },
        fontFileUrl(url: string) {
            CommunityService.addFontFaceFromNameAndUrl(this.fontFamily, url);
        },
    },
    computed: {
        logo(): string | undefined {
            const { web, community } = this.$store.state.styling;

            if (web === null && community === null) return undefined;

            const communityLogo = community?.logo;
            const webLogo = web?.['header-logo_content'];

            return (webLogo === undefined || webLogo === '') ? (communityLogo ?? undefined) : webLogo;
        },
        title(): string {
            return this.$store.state.title;
        },
        community(): string {
            const {community} = this.$store.getters;
            return community?.slug || '';
        },
        route(): string {
            const {name} = this.$route;
            return name || '';
        },
        animateHeight(): boolean {
            return features.canAnimateHeight;
        },
        headerColor(): string {
            return this.$store.state.styling?.web?.['header_background-color'] ?? '#A6A6A6';
        },
        headerBackgroundSize(): string {
            if (this.$store.state.styling?.web === undefined) return '';

            return this.$store.state.styling?.web?.['header_background-size'] ?? 'cover';
        },
        headerBackgroundImage(): string {
            return this.$store.state.styling?.web?.['header_background-image'] ?? undefined;
        },
        fontSourceGoogleEnabled(): boolean {
            return this.$store.state.styling?.web?.['font_source_google'] === '1' ?? false;
        },
        fontFamily(): string {
            const { web, community } = this.$store.state.styling;

            if (web === null && community === null) return '';

            const communityFontFamily = community?.['font-fam']?.replace(/^font-family: /, '');

            const googleFontFamily = this.getStyle('--body-google-font-family');
            if (this.fontSourceGoogleEnabled && googleFontFamily) {
                const fontWeight = this.getStyle('--body-font-weight');
                CommunityService.loadGoogleFontFromFamilyNameAndWeight(googleFontFamily, fontWeight);

                return googleFontFamily;
            }

            const fontFamily = this.getStyle('--body-font-family');

            return fontFamily || communityFontFamily || 'Quicksand Variable';
        },
        fontFileUrl(): string {
            if (this.fontSourceGoogleEnabled) return '';

            const { web, community } = this.$store.state.styling;

            if (web === null && community === null) return '';

            const communityFontUrl = community?.['font-url'];

            return web?.['body_font-file'] || communityFontUrl || '';
        },
    },
    data() {
        return {
            visible: false,
            scrollable: false,
            styled: false,
            communityStylesheet: null as HTMLLinkElement | null,
            isLoading: false,
        };
    },
    created() {
        window.addEventListener('resize', this.resizeEventHandler);
        this.registerVirtualKeyboard(!ua.ios, navigator);
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeEventHandler);
        this.registerVirtualKeyboard(false, navigator);
    },
    beforeMount() {
        const queryParam = this.$route.query?.l;
        const queryLanguage: string = Array.isArray(queryParam) ? (queryParam[0] || '') : (queryParam || '');

        const isValidLanguageCode = (code: string) => /^[a-z]{2}(-[A-Z]{2})?$/.test(code);

        const language: string = isValidLanguageCode(queryLanguage) ? queryLanguage : 'nl';

        if (!isValidLanguageCode(language)) {
            console.warn('Invalid language code:', language);
            return; // Or handle the validation case appropriately
        }

        document.documentElement.lang = language;
    },
    async mounted() {
        await this.prepareCommunity();
        this.determineHeaderExpansionState();
    },
});
