/* eslint @typescript-eslint/no-explicit-any: 0 */
import ConversationApi from '@/apis/conversation.api';
import ConversationModel from '@/models/conversation.model';

import InstructionModel from '@/models/instruction.model';
import {CrmAuthType} from '@/helpers/constants';
import {ApiError} from '@/apis/abbi.api';

type newInstructionCallbackFunction = (newInstruction: InstructionModel, completed: number) => void;
type errorCallbackFunction = (error: Record<string, any>, group: string|undefined) => void;
export default class ConversationService {
    private static newInstructionCallback: newInstructionCallbackFunction;

    private static errorCallback: errorCallbackFunction;

    public static SetNewInstructionCallback(callback: newInstructionCallbackFunction) {
        this.newInstructionCallback = callback;
    }

    public static SetErrorCallback(callback: errorCallbackFunction) {
        this.errorCallback = callback;
    }

    public static GetConversationScriptState(scriptUuid: string) {
        return ConversationApi.GetChatScriptState(scriptUuid);
    }

    public static StartNewConversation(
        communitySlug: string,
        scriptUuid: string,
        conversationUuid: string|null,
        metadata: { [key: string]: string } | null = null,
    ) {
        const urlParams = new URLSearchParams(window.location.search);
        const externalId = urlParams.get('externalId');
        return ConversationApi.CreateStartedConversation(communitySlug, scriptUuid as string, metadata, externalId)
            .then((response) => {
                let conversation = null;
                let name = null;

                if (response !== undefined) {
                    conversation = response.conversation as ConversationModel;
                    name = response.name;
                    this.newInstructionCallback(response.start as InstructionModel, 0);
                }

                return {conversation, name};
            });
    }

    public static StartConversation(
        communitySlug: string,
        scriptUuid: string,
        conversationUuid: string,
    ) {
        return ConversationApi.StartConversation(communitySlug, scriptUuid, conversationUuid)
            .then((response) => {
                if (response !== undefined) {
                    this.newInstructionCallback(response.start as InstructionModel, 0);
                }
            });
    }

    public static ContinueConversation(
        communitySlug: string,
        scriptUuid: string,
        conversationUuid: string,
        historyCb: any,
        metadata: { [key: string]: string } | null = null,
    ) {
        return ConversationApi
            .GetChatHistory(communitySlug, scriptUuid, conversationUuid)
            .then((response) => {
                if (response !== undefined) {
                    historyCb(response);
                }
            })
            .catch((error: ApiError) => {
                if (error.response?.status === 404) {
                    ConversationService.StartNewConversation(
                        communitySlug,
                        scriptUuid,
                        null,
                        metadata,
                    );
                }
            });
    }

    public static GetNextInstruction(
        communitySlug: string,
        conversationUuid: string,
        currentInstructionUuid: string,
    ) {
        const urlParams = new URLSearchParams(window.location.search);
        const externalId = urlParams.get('externalId');
        ConversationApi
            .SendConversationEvent(communitySlug, conversationUuid, currentInstructionUuid, {
                instruction: currentInstructionUuid,
                type: 'reply',
            }, externalId)
            .catch((error) => {
                this.errorCallback(error, 'advance');
            })
            .then((response) => {
                if (response !== undefined) {
                    this.newInstructionCallback(response.instruction as InstructionModel, response.completed);
                }
            });
    }

    public static AnswerInstruction(
        communitySlug: string,
        conversationUuid: string,
        currentInstructionUuid: string,
        answer: string | number | Record<string, boolean>,
    ): Promise<boolean> {
        return ConversationApi
            .SendConversationReply(communitySlug, conversationUuid, currentInstructionUuid, answer)
            .catch((error) => {
                this.errorCallback(error, 'answer');
            })
            .then((response) => {
                if (response === undefined) return response;

                let res: Record<string, any>|undefined = response;
                const instruction = res.instruction as InstructionModel;
                if (Number(instruction?.action?.params?.crmAuthType) === CrmAuthType.PreAuthenticated) {
                    const externalId = (new URLSearchParams(window.location.search)).get('externalId');
                    try {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        res = ConversationApi.SendConversationReply(communitySlug, conversationUuid, instruction.uuid, String(externalId));
                    } catch (error) {
                        this.errorCallback(error, 'answer');
                        res = undefined;
                    }
                }

                return res;
            })
            .then((response) => {
                if (response !== undefined) {
                    this.newInstructionCallback(response.instruction as InstructionModel, response.completed);
                    return true;
                }
                return false;
            });
    }

    public static AnswerFileInstruction(
        communitySlug: string,
        conversationUuid: string,
        currentInstructionUuid: string,
        fileData: File,
    ): Promise<boolean> {
        return ConversationApi
            .SendFile(communitySlug, conversationUuid, fileData)
            .catch((error) => {
                this.errorCallback(error, 'answer');
            })
            .then((response: Record<string, any> | undefined | void) => {
                if (response !== undefined) {
                    this.newInstructionCallback(response.instruction as InstructionModel, response.completed);
                    return true;
                }
                return false;
            });
    }

    public static RevertToLastQuestion(
        communitySlug: string,
        conversationUuid: string,
    ) {
        return ConversationApi.RevertToLastQuestion(communitySlug, conversationUuid)
            .catch((error) => {
                this.errorCallback(error, 'revert');
                return false;
            });
    }
}
