





















import Vue, {PropType} from 'vue';
import InstructionModel from '@/models/instruction.model';
import AnswerInput from '@/components/answer-input/index.vue';
import {resolveInputComponent, resolveInputProps} from '@/components/answer-input/resolver';
import features from '@/helpers/features';

export default Vue.extend({
    props: {
        instruction: {
            type: Object as PropType<InstructionModel>,
            default: null,
        },
        height: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isMounted: false,
            transitionDelay: null as null | string,
        };
    },
    created() {
        window.addEventListener('resize', this.updateInputDimensions);
    },
    destroyed() {
        window.removeEventListener('resize', this.updateInputDimensions);
    },
    mounted(): void {
        this.updateInputDimensions();
        this.$nextTick(() => {
            this.isMounted = true;
        });
    },
    computed: {
        style(): object {
            const {height, transitionProperties, transitionDelay} = this;

            return {
                '--transition-properties': transitionProperties.join(','),
                transitionDelay,
                ...(height !== '0px' ? {height} : {}),
            };
        },
        transitionProperties(): Array<string> {
            const properties = ['margin', 'opacity'];

            if (features.canAnimateHeight) {
                properties.push('height');
            }

            return properties;
        },
        input(): typeof AnswerInput|null {
            let component = null;

            if (this.instruction) {
                try {
                    component = resolveInputComponent(this.instruction);
                } catch (error) {
                    component = null;
                }
            }

            return component;
        },
        inputProps(): object {
            let props = {};

            if (this.input && this.instruction) {
                props = resolveInputProps(this.instruction);
            }

            return props;
        },
    },
    methods: {
        onChange(): void {
            this.transitionDelay = '0s';
            this.$emit('change');
            this.$nextTick(() => {
                this.updateInputDimensions();
                setTimeout(() => {
                    this.transitionDelay = null;
                }, 1000);
            });
        },
        updateInputDimensions(): void {
            const component = this.$refs.component as Vue | null;

            if (component) {
                const {$el} = component;
                this.$emit(
                    'input-resize',
                    DOMRectReadOnly.fromRect($el.getBoundingClientRect()),
                );
            }
        },
    },
});
