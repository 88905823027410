



















import QuestionInput from '@/components/answer-input/QuestionInput.vue';
import Slider from '@/components/answer-input/control/Slider.vue';

export default QuestionInput.extend({
    name: 'Nps',
    components: {Slider},
    data() {
        return {
            data: '5',
            valid: this.skipable,
        };
    },
});
