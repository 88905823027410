export enum Status {
    Unknown = 0,
    Preparation,
    Live,
    Closed,
    Deleted,
}

export enum CrmAuthType {
    Anonymous = 1,
    Authenticated,
    PreAuthenticated,
}

export enum ValidationType {
    Email = 1,
    Numeric,
    NotEmpty,
    Uuid,
}
