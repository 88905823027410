
















import Message from '@/components/messages/Message.vue';
import getStyleProperty from '@/helpers/style';

import {FaRating} from 'vue-rate-it';
import Star from 'vue-rate-it/glyphs/star';

export default Message.extend({
    name: 'StarRatingMessage',
    components: {
        FaRating,
    },
    created() {
        this.glyph = Star;
    },
    computed: {
        inactiveColor(): string {
            return getStyleProperty(this.$store.state.styling?.web, 'star_rating_inactive-color', '#D8D8D8FF');
        },
        activeColor(): string {
            return getStyleProperty(this.$store.state.styling?.web, 'star_rating_active-color', '#FED055FF');
        },
        borderColor(): string {
            return getStyleProperty(this.$store.state.styling?.web, 'star_rating_border-color', '#FFFFFF00');
        },
        borderWidth(): number {
            return parseInt(getStyleProperty(this.$store.state.styling?.web, 'star_rating_border-width', '2'), 0) ?? 1;
        },
        size(): number {
            const rootFontSizeInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
            const bodyFontSize = getStyleProperty(this.$store.state.styling?.web, 'body_font-size', '1rem');

            const emFactor = parseFloat(bodyFontSize);

            return (rootFontSizeInPixels * emFactor) - this.borderWidth;
        },
        rating(): number {
            return parseFloat(this.content);
        },
    },
    data: () => ({
        isActive: true,
        isDisabled: true,
        glyph: '',
    }),
});
