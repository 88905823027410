









/* eslint @typescript-eslint/ban-ts-ignore: 0 */
import vue, {PropType} from 'vue';
import ChatMessageModel from '@/models/chat-message.model';
import Message from '@/components/messages/Message.vue';
import {resolveMessageComponent, resolveMessageProps} from '@/components/messages/resolver';

export default vue.extend({
    props: {
        message: {
            type: Object as PropType<ChatMessageModel>,
            required: true,
        },
        latest: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        component(): typeof Message {
            return resolveMessageComponent(this.message);
        },
        props(): object {
            return resolveMessageProps(this.message, this.latest);
        },
    },
});
