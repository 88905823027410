import {eventCallback} from '@/helpers/event-dispatcher';
import ActionModel from '@/models/action.model';
import InstructionHalter from '@/interfaces/chat/instruction-halter.interface';

export default (chat: InstructionHalter): EventListener => eventCallback(
    (action: ActionModel, event: CustomEvent) => {
        if (action.params?.status === 'expired') {
            event.preventDefault();
            chat.halt();
        }
    },
);
