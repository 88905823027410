
















import ActionInput from '@/components/answer-input/ActionInput.vue';
import CrmNormalizer from '@/normalizer/crm.normalizer';
import createCrmNormalizer from '@/factories/crm.normalizer.factory';
import {debounce} from 'debounce';
import {CrmAuthType, ValidationType} from '@/helpers/constants';

const fieldTypes: Record<ValidationType, string> = {
    [ValidationType.Email]: 'email',
    [ValidationType.Numeric]: 'tel',
    [ValidationType.Uuid]: 'text',
    [ValidationType.NotEmpty]: 'text',
};

export default ActionInput.extend({
    name: 'CrmInput',
    computed: {
        normalizer(): CrmNormalizer {
            return createCrmNormalizer(this.action);
        },
        needsInput(): boolean {
            return Number(this.params.crmAuthType) !== CrmAuthType.PreAuthenticated;
        },
        inputType(): string {
            return fieldTypes[this.params.validation as ValidationType];
        },
    },
    data() {
        return {
            data: '',
            valid: false,
            validator: null as null | Function,
        };
    },
    mounted(): void {
        this.validator = debounce(this.validateInput.bind(this), 1000);
    },
    watch: {
        valid(valid: boolean): void {
            this.$emit('valid', valid);
        },
        data(value) {
            if (this.validator === null) {
                this.$emit('error', 'Missing authentication validator');
                this.valid = false;
                return;
            }

            this.$emit('error', '');
            this.validator(value);
        },
    },
    methods: {
        validateInput(value: string): void {
            const {normalizer} = this;

            try {
                this.valid = normalizer(value) === value;
            } catch (error) {
                this.valid = false;
                this.$emit('error', error.message);
            }
        },
    },
});
